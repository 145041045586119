#contact {
    padding: 32px;
    margin: 0 auto;
    max-width: 1040px;
    box-sizing: content-box;
    position: relative;
    overflow: hidden;
    grid-template-columns: 1fr 1fr;
}

#footerWrapperContact {
    width: calc(100vw - 96px);
    max-width: 1040px;
    padding: 2rem 48px 40px;
    margin: 1rem auto;
    box-sizing: content-box;
}

.linkColumnContact {
    display: flex;
    flex-direction: column;
    max-width: 220px;
    width: 100%;
}

.linkItemContact {
    font-size: 18px;
    line-height: 30px;
    color: rgba(255, 255, 255, 0.75);
    margin-bottom: 16px;
    transition: .3s ease;
    position: relative;
    left: 0;
}

.linkItemContact:hover {
    color: rgb(84 214 110);
    left: 6px;
}


#linkListContact{
    display: flex;
    align-items: center;
}


.linkTitleContact{
    font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 24px;
	text-transform: uppercase;
	color: rgba(255, 255, 255, 0.4);
	margin-bottom: 16px;
}

#sloganContact{
	min-width: 280px;
	letter-spacing: 0.02em;
	font-size: 20px;
	font-style: italic;
	line-height: 30px;
}

#socialContainerContact{
    display: flex;
    align-items: center;
}

#socialIconsGitHubContact{
    transition: 0.3s ease;
    color: white;
    border-radius: 20px;
    padding: 8px;
}

#socialIconsGitHubContact:hover{
    background-color: #ac00e6;
    transform: scale(1.2);
    cursor: pointer;
}

#socialIconsLinkedinContact{
    transition: 0.3s ease;
    color: white;
    border-radius: 20px;
    padding: 8px;
}

#socialIconsLinkedinContact:hover{
    background-color: #4d79ff;
    transform: scale(1.2);
    cursor: pointer;
}


#socialIconsContainerContact{
    max-width: 1040px;
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 4%;
}


#licenseArea{
    text-align: center;
}

#license {
    color: rgba(255, 255, 255, 0.5);
	min-width: 280px;
	letter-spacing: 0.02em;
	font-size: 15px;
	line-height: 30px;
}



#titleContact{
    display: block;
    font-weight: 800;
    font-size: 56px;
    line-height: 56px;
    width: max-content;
    max-width: 100%;
    background: linear-gradient(121.57deg, #FFFFFF 18.77%, rgba(255, 255, 255, 0.66) 60.15%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: auto;
    padding: 58px 0 16px;
}

.inputContact {
    display: block;
	width: 80%;
    margin-right: auto;
    margin-left: auto;
    margin-top: 33px;
    margin-bottom: 33px;
    border-radius: 15px;
    padding: 14px;
}


.messageInputContact{
    display: block;
	width: 80%;
    margin-right: auto;
    margin-left: auto;
    margin-top: 25px;
    margin-bottom: 25px;
    border-radius: 15px;
    padding: 14px;
}


#successMessageContact{
    display: block;
    margin-top: 25px;
    color: #3e9e51;
    font-family: Space Grotesk,sans-serif;
    font-size: 2rem;
    text-align: center;
    margin-bottom: 25px;
}

#errorMessageContact{
    display: block;
    margin-top: 25px;
    color: #f60c0c;
    font-family: Space Grotesk,sans-serif;
    font-size: 2rem;
    text-align: center;
    margin-bottom: 25px;
}


#editButtonContact{
    appearance: none;
    background-color: #2348C3;
    border-radius: 40em;
    border-style: none;
    box-shadow: #03167E 0 -12px 6px inset;
    box-sizing: border-box;
    color: #C8C9D0;
    cursor: pointer;
    display: block;
    font-family: -apple-system,sans-serif;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -.24px;
    padding: 1.4rem 4.3rem;
    margin: auto;
    margin-bottom: 3px;
    outline: none;
    quotes: auto;
    text-align: center;
    text-decoration: none;
    transition: all .5s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}

#editButtonContact:hover{
    background-color: #1E33E7;
    box-shadow: #03167E 0 -6px 8px inset;
    transform: scale(1.125);
}

#editButtonContact:active{
    transform: scale(1.025);
}


#submitButtonContact{
    appearance: none;
	background-color: #1d3c19;
	border-radius: 40em;
	border-style: none;
	box-shadow: #172b15 0 -12px 6px inset;
	box-sizing: border-box;
	color: rgb(186 200 191);
	cursor: pointer;
	display: block;
	font-family: -apple-system,sans-serif;
	font-size: 16px;
	font-weight: 700;
	letter-spacing: -.24px;
	padding: 1.4rem 4.3rem;
	margin: auto;
	margin-bottom: 15px;
	outline: none;
	quotes: auto;
	text-align: center;
	text-decoration: none;
	transition: all .5s;
	user-select: none;
	-webkit-user-select: none;
	touch-action: manipulation;
}

#submitButtonContact:hover{
    background-color: #429338;
    box-shadow: #1d3c19 0 -6px 8px inset;
    transform: scale(1.125);
}

#submitButtonContact:active{
    transform: scale(1.025);
}


#sectionTitleContact{
    font-weight: 800;
    font-size: 65px;
    line-height: 72px;
    width: max-content;
    max-width: 100%;
    background: linear-gradient(121.57deg, #FFFFFF 18.77%, rgba(255, 255, 255, 0.66) 60.15%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 2%;
}


#recaptchaStyleContact{
    display: block;
	width: 25%;
    margin: auto;
	border-radius: 14px;
}

/* Au point lg */
@media screen and (max-width: 1024px){
    #linkListContact{
        padding: 32px 0 16px;
    }
}


/* Au point md */
@media screen and (max-width: 768px) {

    #contact {
        flex-direction: column;
        align-items: baseline;
    }

    .linkItemContact {
        font-size: 16px;
        line-height: 28px;
        display: flex;
    }

    #linkListContact{
        width: 100%;
		padding: 32px 0 16px;
		gap: 16px;
    }

    #sloganContact{
        font-size: 16px;
		line-height: 28px;
    }

    #socialContainerContact{
        justify-content: center;
		padding-right: 16px;
		flex-wrap: wrap;
    }

    #socialIconsContainerContact{
        display: flex;
        justify-content: space-between;
    }

    #titleContact{
        font-size: 48px;
        line-height: 48px;
        margin-bottom: 12px;
        padding: 0;
    }

    #editButtonContact{
        font-size: 16px;
    }

    #submitButtonContact{
        font-size: 16px;
    }

    #sectionTitleContact{
        font-size: 48px;
        line-height: 48px;
        margin-bottom: 12px;
        padding: 0;
    }
}


/* Au point sm */
@media screen and (max-width: 640px) {

    #contact {
        display: flex;
        flex-direction: column;
        margin: 0 0 32px;
        align-items: center;
    }

    #footerWrapperContact {
        padding: 0 16px 48px;
        width: calc(100vw - 32px);
    }

    .linkItemContact {
        font-size: 16px;
        line-height: 14px;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
    }

    #linkListContact{
        width: 100%;
		padding: 32px 4px 16px;
		gap: 5px;
    }

    .linkTitleContact{
        font-size: 10px;
		line-height: 12px;
		margin-bottom: 8px;
    }

    #sloganContact{
        line-height: 22px;
		font-size: 14px;
		min-width: 100px;
    }

    #socialIconsContainerContact{
        display: flex;
        width: 100%;
        flex-direction: column;
    }

    #titleContact{
        font-size: 32px;
        line-height: 40px;
        font-size: 32px;
        line-height: 40px;
        margin-bottom: 8px;
        padding: 0;
        max-width: 100%;
    }

    #editButtonContact{
        font-size: 14px;
    }

    #submitButtonContact{
        font-size: 14px;
    }

    #sectionTitleContact{
        font-size: 32px;
        line-height: 40px;
        font-size: 32px;
        line-height: 40px;
        margin-bottom: 8px;
        padding: 0;
        max-width: 100%;
    }
}