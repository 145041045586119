#containerHome{
    width: 100%;
}

#smallContainerHome {
    width: 100%;
    background-color: #030503;
}

#sectionHome{
    display: grid;
    flex-direction: row;
    padding: 32px;
    margin: 0 auto;
    max-width: 1040px;
    box-sizing: content-box;
    position: relative;
    overflow: hidden;
    grid-template-columns: 1fr 1fr;
}


@media screen and (max-width: 768px) {
    #sectionHome{
        padding: 24px 48px 0;
        flex-direction: column;
    }    
}

@media screen and (max-width: 640px){

    #sectionHome{
        padding: 0;
        width: calc(100vw - 32px);
        flex-direction: column;
    }
}