#backgroundVideo{
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
}


#textGroup{
    z-index: 10;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

#firstHeader{
    font-size: 18px;
    font-weight: 300;
    color: rgb(186 200 191);
    margin-bottom: 15px;
    text-transform: uppercase;
    letter-spacing: 4px;
    text-align: center;
}

#secondHeader{
    font-size: 18px;
    font-weight: 300;
    color: #bac8bf;
    margin-bottom: 15px;
    text-transform: uppercase;
    letter-spacing: 4px;
    text-align: center;
}

#thirdHeader{
    color: rgb(186 200 191);
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 6px;
    text-align: center;
    padding: 12px;
    box-shadow: 3px 3px 20px #bac8bf;
}


#home{
    width: 100%;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    height: 100vh;
    z-index: -1000;
}