#about{
    display: grid;
    flex-direction: row;
    padding: 32px;
    margin: 0 auto;
    max-width: 1040px;
    box-sizing: content-box;
    position: relative;
    overflow: hidden;
    grid-template-columns: 1fr 1fr;
}


#biographyTitle{
    font-weight: 800;
    font-size: 65px;
    line-height: 72px;
    width: max-content;
    max-width: 100%;
    background: linear-gradient(121.57deg, #FFFFFF 18.77%, rgba(255, 255, 255, 0.66) 60.15%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 2%;
    padding: 58px 0 16px;
}

#leftSectionBiography {
    width: 100%;
}

#imgBiography {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 3px 3px 20px #1a551b;
    border: 1px solid #54d66e;
}

.sectionTextBiography {
    margin-top: 30px;
    max-width: 800px;
    font-size: 17px;
    font-weight: 300;
    text-align: justify;
}


#downloadButtonBiography {
    appearance: none;
    background-color: #1d3c19;
    border-radius: 40em;
    border-style: none;
    box-shadow: #172b15 0 -12px 6px inset;
    box-sizing: border-box;
    color: rgb(186 200 191);
    cursor: pointer;
    font-family: -apple-system,sans-serif;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -.24px;
    padding: 1.4rem 4.3rem;
    margin: auto;
    outline: none;
    quotes: auto;
    text-align: center;
    text-decoration: none;
    transition: all .5s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}

#downloadButtonBiography:hover {
    background-color: #429338;
    box-shadow: #1d3c19 0 -6px 8px inset;
    transform: scale(1.125);
}

#downloadButtonBiography:active {
    transform: scale(1.025);
}

#editButtonBiography {
    appearance: none;
    background-color: #2348C3;
    border-radius: 40em;
    border-style: none;
    box-shadow: #03167E 0 -12px 6px inset;
    box-sizing: border-box;
    color: #C8C9D0;
    cursor: pointer;
    display: table;
    font-family: -apple-system,sans-serif;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -.24px;
    padding: 1.4rem 4.3rem;
    margin: auto;
    margin-bottom: 40px;
    outline: none;
    quotes: auto;
    text-align: center;
    text-decoration: none;
    transition: all .5s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}

#editButtonBiography:hover {
    background-color: #1E33E7;
    box-shadow: #03167E 0 -6px 8px inset;
    transform: scale(1.125);
}

#editButtonBiography:active {
    transform: scale(1.025);
}

#normalButtonBiography {
    appearance: none;
    background-color: #1d3c19;
    border-radius: 40em;
    border-style: none;
    box-shadow: #172b15 0 -12px 6px inset;
    box-sizing: border-box;
    color: rgb(186 200 191);
    cursor: pointer;
    display: table;
    font-family: -apple-system,sans-serif;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -.24px;
    padding: 1.4rem 4.3rem;
    margin: auto;
    margin-bottom: 64px;
    outline: none;
    quotes: auto;
    text-align: center;
    text-decoration: none;
    transition: all .5s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}

#normalButtonBiography:hover {
    background-color: #429338;
    box-shadow: #1d3c19 0 -6px 8px inset;
    transform: scale(1.125);
}

#normalButtonBiography:active {
    transform: scale(1.025);
}

/* Au point md */
@media screen and (max-width: 768px) {

    #about{
        padding: 24px 48px 0;
        flex-direction: column;
    }

    #biographyTitle{
        font-size: 56px;
        line-height: 56px;
        margin-bottom: 12px;
        padding: 40px 0 12px;
    }

    #leftSectionBiography {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
    }

    #imgBiography {
        width: 50%;
        height: 50%;
        margin: 200px 0px 20px 75px;
    }

    .sectionTextBiography {
        max-width: 670px;
        font-size: 20px;
        line-height: 32px;
        padding-bottom: 24px;
    }

    #downloadButtonBiography {
        font-size: 16px;
    }

    #editButtonBiography {
        font-size: 16px;
    }

    #normalButtonBiography {
        font-size: 16px;
    }
}


/* Au point sm */
@media screen and (max-width: 640px) {

    #about{
        padding: "0";
        width: calc(100vw - 32px);
        flex-direction: column;
    }

    #biographyTitle{
        font-size: 32px;
        line-height: 40px;
        font-size: 28px;
        line-height: 32px;
        margin-bottom: 8px;
        padding: 16px 0 8px;
        max-width: 100%;
    }

    #leftSectionBiography {
        width: 80%;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
    }

    .sectionTextBiography {
        font-size: 16px;
        line-height: 24px;
        padding-bottom: 16px;
    }

    #downloadButtonBiography {
        font-size: 14px;
    }

    #editButtonBiography {
        font-size: 14px;
    }

    #normalButtonBiography {
        font-size: 14px;
    }
}