

html {
    font-size: 62.5%;
    scroll-behavior: smooth;
}
body {
    font-family: Space Grotesk, sans-serif;
    font-size: 1.6rem;
    background: rgb(23 33 35);
    color: hsl(204,23.8%,95.9%);
    cursor: default;
}

h1,h2,h3,h4,h5,h6,button {
    font-family: Space Grotesk, sans-serif;
}

a {
    text-decoration: none;
}

ul{
    padding-left: 0%;
}

li{
    list-style: none;
}