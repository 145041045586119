#fullProjectSection {
    width: auto;
    height: auto;
    margin: 0px;
    text-align: center;
    position: absolute;
    right: 0px;
    left: 0px;
    background-color: #030503;
}

#fullProjectCarouselSection{
    width: 70%;
    display: inline-block;
}


#imgFullProjectSection{
    width: 100%;
    height: 510px;
    margin-top: 50px;
    border-radius: 10px;
    object-fit: cover;
    overflow: hidden;
}

#paragraphFullProject{
    margin-top: 30px;
    max-width: 800px;
    font-size: 24px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    line-height: 40px;
    font-weight: 300;
    padding-bottom: 3.6rem;
    text-align: justify;
}

#codeSourceLinkGitHubFullProject{
    appearance: none;
    background-color: #1d3c19;
    border-radius: 40em;
    border-style: none;
    box-shadow: #172b15 0 -12px 6px inset;
    box-sizing: border-box;
    color: rgb(186 200 191);
    cursor: pointer;
    font-family: -apple-system,sans-serif;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -.24px;
    padding: 1.4rem 4.3rem;
    display: block;
    margin: auto;
    margin-bottom: 45px;
    margin-right: 36%;
    margin-left: 36%;
    outline: none;
    quotes: auto;
    text-align: center;
    text-decoration: none;
    transition: all .5s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  
    &:hover{
      background-color: #429338;
      box-shadow: #1d3c19 0 -6px 8px inset;
      transform: scale(1.125);
    }
  
    &:active{
      transform: scale(1.025);
    }
}


#buttonGoMenuFullProject{
    appearance: none;
    background-color: #1d3c19;
    border-radius: 40em;
    border-style: none;
    box-shadow: #172b15 0 -12px 6px inset;
    box-sizing: border-box;
    color: rgb(186 200 191);
    cursor: pointer;
    font-family: -apple-system,sans-serif;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -.24px;
    padding: 1.4rem 4.3rem;
    display: block;
    margin: auto;
    margin-bottom: 64px;
    outline: none;
    quotes: auto;
    text-align: center;
    text-decoration: none;
    transition: all .5s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  
    &:hover{
      background-color: #429338;
      box-shadow: #1d3c19 0 -6px 8px inset;
      transform: scale(1.125);
    }
  
    &:active{
      transform: scale(1.025);
    }
}


@media screen and (max-width: 768px) {
    #codeSourceLinkGitHubFullProject{
        font-size: 20px;
    }

    #buttonGoMenuFullProject{
        font-size: 20px;
    }
}

@media screen and (max-width: 640px) {
    #codeSourceLinkGitHubFullProject{
        font-size: 14px;
    }

    #buttonGoMenuFullProject{
        font-size: 14px;
    }
}