.navbar {
    width: 100%;
    z-index: 2;
    background-color: #152025c2;
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.2rem;
}

.LogoSection {
    display: flex;
    align-items: center;
    color: white;
    margin: 2rem;
    margin-left: 5rem;
}


.toggleButton {
    position: absolute;
    margin: 3.5rem;
    right: 0;
    display: none;
    flex-direction: column;
    justify-content: space-between;
    height: 70px;
    width: 70px;
    border: 0;
    padding: 0.5rem;
    background-color: #152025c2;
    cursor: pointer;
}

.navigation-menu:active {
    display: flex;
}

.navigation-menu ul {
    margin: 0;
    padding: 0;
    display: flex;
}

.navigation-menu li {
    list-style: none;
}

.navigation-menu li a {
    font-size: 2rem;
    line-height: 32px;
    color: rgba(255, 255, 255, 0.75);
    transition: 0.4s ease;
    padding: 2rem;
    display: block;
}

.navigation-menu li a:hover {
    color: rgb(84 214 110);
    opacity: 1;
    cursor: pointer;
}



@media screen and (max-width: 640px) {
    .navbar {
        flex-direction: column;
        align-items: flex-start;
    }

    .toggleButton {
        display: flex;
    }

    .navigation-menu {
        width: 100%;
    }

    .navigation-menu ul {
        background-color: #152025c2;
        flex-direction: column;
        position: absolute;
        left: 0;
        width: 100%;
        display: none;
    }

    .navigation-menu li {
        text-align: center;
        margin: 0;
    }

    .navigation-menu li a {
        padding: 1.5rem 0;
        width: 100%;
    }

    .navigation-menu li a:hover{
        background-color: #1c2a31;
    }

    .navigation-menu.expanded ul {
        display: block;
    }
}