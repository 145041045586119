#projects{
    flex-direction: row;
    padding: 32px;
    margin: 0 auto;
    max-width: 1040px;
    box-sizing: content-box;
    position: relative;
    overflow: hidden;
    grid-template-columns: 1fr 1fr;
}

#projectsSectionDivider{
    width: 64px;
    height: 6px;
    border-radius: 10px;
    background-color: #fff;
    background: linear-gradient(90deg, rgba(135,207,152,1) 13%, rgba(70,161,95,1) 27%, rgba(32,124,62,1) 43%, rgba(8,99,33,1) 66%, rgba(5,66,7,1) 88%);
}

#projectsSectionTitle{
    font-weight: 800;
    font-size: 65px;
    line-height: 72px;
    width: max-content;
    max-width: 100%;
    background: linear-gradient(121.57deg, #FFFFFF 18.77%, rgba(255, 255, 255, 0.66) 60.15%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 2%;
    padding: 58px 0 16px;
}


#gridContainerProject{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    padding: 3rem;
    place-items: center;
    column-gap: 2rem;
    row-gap: 3rem;
}

#imgProject{
    width:300px;
    height:300px;
    border-radius: 10px;
    object-fit: cover;
    overflow: hidden;
}

#blogCardProject{
    border-radius: 10px;
    box-shadow: 3px 3px 20px #1a551b;
    border: 1px solid #54d66e;
    text-align: center;
    width: 400px;
    height: 100%;
}

#titleContentProject{
    text-align: center;
    z-index: 20;
    width: 100%;
}

#headerThreeProject {
    margin: 20px auto;
    font-weight: 500;
    letter-spacing: 2px;
    color: #69c55c;
    padding: .5rem 0;
    font-size: 2rem;
}

#hrProject{
    width: 50px;
    height: 3px;
    margin: 20px auto;
    border: 0;
    background: #81d057;
}

#cardInfoProject {
    width: 100%;
    color: rgba(255,255,255,0.5);
    line-height: 1.3em;
    font-size: 24px;
    padding-left: 6%;
    padding-right: 6%;
    text-align: center;
}

#utilityListProject{
    list-style-type: none;
    padding: 0;
    display: grid;
    grid-gap: 20px;
    justify-content: space-around;
    margin: 2.5rem 0;
}

#externalLinksProject{
    color: rgb(186 200 191);
    font-size: 1.6rem;
    padding:1rem 1.5rem;
    background: #36862c;
    box-shadow: #1e451a 0 -12px 6px inset;
    border-radius: 15px;
    transition: 0.5s;
}

#externalLinksProject:hover{
    background-color: #429338;
    box-shadow: #1d3c19 0 -6px 8px inset;
    transform: scale(1.125);
}

#externalLinksProject:active{
    transform: scale(1.025);
}

#tagListProject{
    display: flex;
    justify-content: space-around;
    padding: 2rem;
}

#tagProject {
    color: #d8bfbf;
    font-size: 1.5rem;
}

/*Au point md*/
@media screen and (max-width: 768px) {
    #projects{
        padding: 24px 48px 0;
        flex-direction: column;
    }    

    #projectsSectionDivider{
        width: 48px;
        height: 4px;
    }

    #projectsSectionTitle{
        font-size: 56px;
        line-height: 56px;
        margin-bottom: 12px;
        padding: 40px 0 12px;
    }
}



/* Au point sm */
@media screen and (max-width: 640px) {

    #projects{
        padding: "0";
        width: calc(100vw - 32px);
        flex-direction: column;
    }   

    #projectsSectionDivider{
        width: 32px;
        height: 2px;
    }

    #projectsSectionTitle{
        font-size: 32px;
        line-height: 40px;
        font-size: 28px;
        line-height: 32px;
        margin-bottom: 8px;
        padding: 16px 0 8px;
        max-width: 100%;
    }

    #gridContainerProject{
        display: flex;
        flex-direction: column;
        padding: 2rem;
        padding-bottom: 0;
    }

    #blogCardProject{
        width: 100%;
    }

    #cardInfoProject {
        padding:.3rem
    }
}