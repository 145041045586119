#tech{
    padding: 32px;
    margin: 0 auto;
    max-width: 1040px;
    box-sizing: content-box;
    position: relative;
    overflow: hidden;
    grid-template-columns: 1fr 1fr;
}

.technologyDivider{
    width: 64px;
    height: 6px;
    border-radius: 10px;
    background-color: #fff;
    background: linear-gradient(90deg, rgba(135,207,152,1) 13%, rgba(70,161,95,1) 27%, rgba(32,124,62,1) 43%, rgba(8,99,33,1) 66%, rgba(5,66,7,1) 88%);
}

#technologyTitle{
    font-weight: 800;
    font-size: 65px;
    line-height: 72px;
    width: max-content;
    max-width: 100%;
    background: linear-gradient(121.57deg, #FFFFFF 18.77%, rgba(255, 255, 255, 0.66) 60.15%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 2%;
    padding: 58px 0 16px;
}

#technologyText{
    margin-top: 30px;
    max-width: 800px;
    font-size: 24px;
    line-height: 40px;
    font-weight: 300;
    padding-bottom: 3.6rem;
}

#listTechnologies {
    list-style-type: none;
    display: flex;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(5, 25%);
    gap: 20px;
    margin: 3rem 0;
}

#listContainerTechnologies {
    display: grid;
    flex-direction: column;
}

#listItemTechnologies {
    max-width: 320px;
    flex-direction: column;
}

#listParagraphTechnologies {
    font-size: 18px;
    line-height: 30px;
    color: rgba(255, 255, 255, 0.75);
}

#listTitleTechnologies {
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    margin-bottom: 8px;
}

/* Au point lg */
@media screen and (max-width: 1024px) {
    #listTechnologies {
        margin: 64px 0;
    }
}


/* Au point md */
@media screen and (max-width: 768px) {

    #tech{
        padding: 24px 48px 0;
        flex-direction: column;
    }

    .technologyDivider{
        width: 48px;
        height: 4px;
    }

    #technologyTitle{
        font-size: 56px;
        line-height: 56px;
        margin-bottom: 12px;
        padding: 40px 0 12px;
    }

    #technologyText{
        max-width: 670px;
        font-size: 20px;
        line-height: 32px;
        padding-bottom: 24px;
    }

    #listTechnologies {
        margin: 64px 0;
        gap: 24px
    }

    #listItemTechnologies {
        max-width: 203px;
    }

    #listParagraphTechnologies {
        font-size: 16px;
        line-height: 28px;
    }

    #listTitleTechnologies {
        font-size: 24px;
        line-height: 28px;
    }

    #editButtonTechnologies {
        font-size: 16px;
    }

    #deleteButtonTechnologies {
        font-size: 16px;
    }
}


/* Au point sm */
@media screen and (max-width: 640px) {

    #tech{
        padding: "0";
        width: calc(100vw - 32px);
        flex-direction: column;
    }

    .technologyDivider{
        width: 32px;
        height: 2px;
    }

    #technologyTitle{
        font-size: 32px;
        line-height: 40px;
        font-size: 28px;
        line-height: 32px;
        margin-bottom: 8px;
        padding: 16px 0 8px;
        max-width: 100%;
    }

    #technologyText{
        font-size: 16px;
        line-height: 24px;
        padding-bottom: 16px;
    }


    #listTechnologies {
        display: flex;
        flex-direction: column;
        margin: 32px 0;
    }

    #listContainerTechnologies {
        display: flex;
        margin-left: 18px;
    }

    #listItemTechnologies {
        margin-bottom: 14px;
        max-width: 320px;
        flex-direction: row;
    }

    #listParagraphTechnologies {
        font-size: 14px;
        line-height: 22px;
    }

    #listTitleTechnologies {
        font-size: 20px;
        line-height: 28px;
        letter-spacing: 0.02em;
        margin-bottom: 4px;
    }

    #editButtonTechnologies {
        font-size: 14px;
    }

    #deleteButtonTechnologies {
        font-size: 14px;
    }
}