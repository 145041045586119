

html {
    font-size: 62.5%;
    scroll-behavior: smooth;
}
body {
    font-family: Space Grotesk, sans-serif;
    font-size: 1.6rem;
    background: rgb(23 33 35);
    color: hsl(204,23.8%,95.9%);
    cursor: default;
}

h1,h2,h3,h4,h5,h6,button {
    font-family: Space Grotesk, sans-serif;
}

a {
    text-decoration: none;
}

ul{
    padding-left: 0%;
}

li{
    list-style: none;
}
#backgroundVideo{
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
}


#textGroup{
    z-index: 10;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

#firstHeader{
    font-size: 18px;
    font-weight: 300;
    color: rgb(186 200 191);
    margin-bottom: 15px;
    text-transform: uppercase;
    letter-spacing: 4px;
    text-align: center;
}

#secondHeader{
    font-size: 18px;
    font-weight: 300;
    color: #bac8bf;
    margin-bottom: 15px;
    text-transform: uppercase;
    letter-spacing: 4px;
    text-align: center;
}

#thirdHeader{
    color: rgb(186 200 191);
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 6px;
    text-align: center;
    padding: 12px;
    box-shadow: 3px 3px 20px #bac8bf;
}


#home{
    width: 100%;
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative;
    height: 100vh;
    z-index: -1000;
}
#CubeGroup1, #CubeGroup2, #CubeGroup3, #CubeGroup4, #CubeGroup5, #CubeGroup6, #CubeGroup7, #CubeGroup8, #CubeGroup9, #CubeGroup10, #CubeGroup11, #CubeGroup12, #CubeGroup13, #CubeGroup14, #CubeGroup15, #CubeGroup16 {
    animation: waveAnimation 1.4s ease-in-out alternate infinite;
    animation-fill-mode: forwards;
}

@keyframes waveAnimation{
    to{
        transform: translateY(500px);
    }
}


#CubeGroup1 {
    animation-delay: 0.2s;
}

#CubeGroup2 {
    animation-delay: 0.4s;
}

#CubeGroup3 {
    animation-delay: 0.6s;
}

#CubeGroup4 {
    animation-delay: 0.8s;
}

#CubeGroup5 {
    animation-delay: 1s;
}

#CubeGroup6 {
    animation-delay: 1.2s;
}

#CubeGroup7 {
    animation-delay: 1.4s;
}

#CubeGroup8 {
    animation-delay: 1.6s;
}


#CubeGroup9 {
    animation-delay: 1.8s;
}


#CubeGroup10 {
    animation-delay: 2s;
}

#CubeGroup11 {
    animation-delay: 2.2s;
}

#CubeGroup12 {
    animation-delay: 2.4s;
}

#CubeGroup13 {
    animation-delay: 2.6s;
}

#CubeGroup14 {
    animation-delay: 2.8s;
}

#CubeGroup15 {
    animation-delay: 3s;
}

#CubeGroup16 {
    animation-delay: 3.2s;
}
#containerHome{
    width: 100%;
}

#smallContainerHome {
    width: 100%;
    background-color: #030503;
}

#sectionHome{
    display: grid;
    flex-direction: row;
    padding: 32px;
    margin: 0 auto;
    max-width: 1040px;
    box-sizing: content-box;
    position: relative;
    overflow: hidden;
    grid-template-columns: 1fr 1fr;
}


@media screen and (max-width: 768px) {
    #sectionHome{
        padding: 24px 48px 0;
        flex-direction: column;
    }    
}

@media screen and (max-width: 640px){

    #sectionHome{
        padding: 0;
        width: calc(100vw - 32px);
        flex-direction: column;
    }
}
.navbar {
    width: 100%;
    z-index: 2;
    background-color: #152025c2;
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.2rem;
}

.LogoSection {
    display: flex;
    align-items: center;
    color: white;
    margin: 2rem;
    margin-left: 5rem;
}


.toggleButton {
    position: absolute;
    margin: 3.5rem;
    right: 0;
    display: none;
    flex-direction: column;
    justify-content: space-between;
    height: 70px;
    width: 70px;
    border: 0;
    padding: 0.5rem;
    background-color: #152025c2;
    cursor: pointer;
}

.navigation-menu:active {
    display: flex;
}

.navigation-menu ul {
    margin: 0;
    padding: 0;
    display: flex;
}

.navigation-menu li {
    list-style: none;
}

.navigation-menu li a {
    font-size: 2rem;
    line-height: 32px;
    color: rgba(255, 255, 255, 0.75);
    transition: 0.4s ease;
    padding: 2rem;
    display: block;
}

.navigation-menu li a:hover {
    color: rgb(84 214 110);
    opacity: 1;
    cursor: pointer;
}



@media screen and (max-width: 640px) {
    .navbar {
        flex-direction: column;
        align-items: flex-start;
    }

    .toggleButton {
        display: flex;
    }

    .navigation-menu {
        width: 100%;
    }

    .navigation-menu ul {
        background-color: #152025c2;
        flex-direction: column;
        position: absolute;
        left: 0;
        width: 100%;
        display: none;
    }

    .navigation-menu li {
        text-align: center;
        margin: 0;
    }

    .navigation-menu li a {
        padding: 1.5rem 0;
        width: 100%;
    }

    .navigation-menu li a:hover{
        background-color: #1c2a31;
    }

    .navigation-menu.expanded ul {
        display: block;
    }
}
#about{
    display: grid;
    flex-direction: row;
    padding: 32px;
    margin: 0 auto;
    max-width: 1040px;
    box-sizing: content-box;
    position: relative;
    overflow: hidden;
    grid-template-columns: 1fr 1fr;
}


#biographyTitle{
    font-weight: 800;
    font-size: 65px;
    line-height: 72px;
    width: -webkit-max-content;
    width: max-content;
    max-width: 100%;
    background: linear-gradient(121.57deg, #FFFFFF 18.77%, rgba(255, 255, 255, 0.66) 60.15%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 2%;
    padding: 58px 0 16px;
}

#leftSectionBiography {
    width: 100%;
}

#imgBiography {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 3px 3px 20px #1a551b;
    border: 1px solid #54d66e;
}

.sectionTextBiography {
    margin-top: 30px;
    max-width: 800px;
    font-size: 17px;
    font-weight: 300;
    text-align: justify;
}


#downloadButtonBiography {
    -webkit-appearance: none;
            appearance: none;
    background-color: #1d3c19;
    border-radius: 40em;
    border-style: none;
    box-shadow: #172b15 0 -12px 6px inset;
    box-sizing: border-box;
    color: rgb(186 200 191);
    cursor: pointer;
    font-family: -apple-system,sans-serif;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -.24px;
    padding: 1.4rem 4.3rem;
    margin: auto;
    outline: none;
    quotes: auto;
    text-align: center;
    text-decoration: none;
    transition: all .5s;
    -ms-user-select: none;
        user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}

#downloadButtonBiography:hover {
    background-color: #429338;
    box-shadow: #1d3c19 0 -6px 8px inset;
    transform: scale(1.125);
}

#downloadButtonBiography:active {
    transform: scale(1.025);
}

#editButtonBiography {
    -webkit-appearance: none;
            appearance: none;
    background-color: #2348C3;
    border-radius: 40em;
    border-style: none;
    box-shadow: #03167E 0 -12px 6px inset;
    box-sizing: border-box;
    color: #C8C9D0;
    cursor: pointer;
    display: table;
    font-family: -apple-system,sans-serif;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -.24px;
    padding: 1.4rem 4.3rem;
    margin: auto;
    margin-bottom: 40px;
    outline: none;
    quotes: auto;
    text-align: center;
    text-decoration: none;
    transition: all .5s;
    -ms-user-select: none;
        user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}

#editButtonBiography:hover {
    background-color: #1E33E7;
    box-shadow: #03167E 0 -6px 8px inset;
    transform: scale(1.125);
}

#editButtonBiography:active {
    transform: scale(1.025);
}

#normalButtonBiography {
    -webkit-appearance: none;
            appearance: none;
    background-color: #1d3c19;
    border-radius: 40em;
    border-style: none;
    box-shadow: #172b15 0 -12px 6px inset;
    box-sizing: border-box;
    color: rgb(186 200 191);
    cursor: pointer;
    display: table;
    font-family: -apple-system,sans-serif;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -.24px;
    padding: 1.4rem 4.3rem;
    margin: auto;
    margin-bottom: 64px;
    outline: none;
    quotes: auto;
    text-align: center;
    text-decoration: none;
    transition: all .5s;
    -ms-user-select: none;
        user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}

#normalButtonBiography:hover {
    background-color: #429338;
    box-shadow: #1d3c19 0 -6px 8px inset;
    transform: scale(1.125);
}

#normalButtonBiography:active {
    transform: scale(1.025);
}

/* Au point md */
@media screen and (max-width: 768px) {

    #about{
        padding: 24px 48px 0;
        flex-direction: column;
    }

    #biographyTitle{
        font-size: 56px;
        line-height: 56px;
        margin-bottom: 12px;
        padding: 40px 0 12px;
    }

    #leftSectionBiography {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
    }

    #imgBiography {
        width: 50%;
        height: 50%;
        margin: 200px 0px 20px 75px;
    }

    .sectionTextBiography {
        max-width: 670px;
        font-size: 20px;
        line-height: 32px;
        padding-bottom: 24px;
    }

    #downloadButtonBiography {
        font-size: 16px;
    }

    #editButtonBiography {
        font-size: 16px;
    }

    #normalButtonBiography {
        font-size: 16px;
    }
}


/* Au point sm */
@media screen and (max-width: 640px) {

    #about{
        padding: "0";
        width: calc(100vw - 32px);
        flex-direction: column;
    }

    #biographyTitle{
        font-size: 32px;
        line-height: 40px;
        font-size: 28px;
        line-height: 32px;
        margin-bottom: 8px;
        padding: 16px 0 8px;
        max-width: 100%;
    }

    #leftSectionBiography {
        width: 80%;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
    }

    .sectionTextBiography {
        font-size: 16px;
        line-height: 24px;
        padding-bottom: 16px;
    }

    #downloadButtonBiography {
        font-size: 14px;
    }

    #editButtonBiography {
        font-size: 14px;
    }

    #normalButtonBiography {
        font-size: 14px;
    }
}
#projects{
    flex-direction: row;
    padding: 32px;
    margin: 0 auto;
    max-width: 1040px;
    box-sizing: content-box;
    position: relative;
    overflow: hidden;
    grid-template-columns: 1fr 1fr;
}

#projectsSectionDivider{
    width: 64px;
    height: 6px;
    border-radius: 10px;
    background-color: #fff;
    background: linear-gradient(90deg, rgba(135,207,152,1) 13%, rgba(70,161,95,1) 27%, rgba(32,124,62,1) 43%, rgba(8,99,33,1) 66%, rgba(5,66,7,1) 88%);
}

#projectsSectionTitle{
    font-weight: 800;
    font-size: 65px;
    line-height: 72px;
    width: -webkit-max-content;
    width: max-content;
    max-width: 100%;
    background: linear-gradient(121.57deg, #FFFFFF 18.77%, rgba(255, 255, 255, 0.66) 60.15%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 2%;
    padding: 58px 0 16px;
}


#gridContainerProject{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    padding: 3rem;
    place-items: center;
    grid-column-gap: 2rem;
    column-gap: 2rem;
    grid-row-gap: 3rem;
    row-gap: 3rem;
}

#imgProject{
    width:300px;
    height:300px;
    border-radius: 10px;
    object-fit: cover;
    overflow: hidden;
}

#blogCardProject{
    border-radius: 10px;
    box-shadow: 3px 3px 20px #1a551b;
    border: 1px solid #54d66e;
    text-align: center;
    width: 400px;
    height: 100%;
}

#titleContentProject{
    text-align: center;
    z-index: 20;
    width: 100%;
}

#headerThreeProject {
    margin: 20px auto;
    font-weight: 500;
    letter-spacing: 2px;
    color: #69c55c;
    padding: .5rem 0;
    font-size: 2rem;
}

#hrProject{
    width: 50px;
    height: 3px;
    margin: 20px auto;
    border: 0;
    background: #81d057;
}

#cardInfoProject {
    width: 100%;
    color: rgba(255,255,255,0.5);
    line-height: 1.3em;
    font-size: 24px;
    padding-left: 6%;
    padding-right: 6%;
    text-align: center;
}

#utilityListProject{
    list-style-type: none;
    padding: 0;
    display: grid;
    grid-gap: 20px;
    justify-content: space-around;
    margin: 2.5rem 0;
}

#externalLinksProject{
    color: rgb(186 200 191);
    font-size: 1.6rem;
    padding:1rem 1.5rem;
    background: #36862c;
    box-shadow: #1e451a 0 -12px 6px inset;
    border-radius: 15px;
    transition: 0.5s;
}

#externalLinksProject:hover{
    background-color: #429338;
    box-shadow: #1d3c19 0 -6px 8px inset;
    transform: scale(1.125);
}

#externalLinksProject:active{
    transform: scale(1.025);
}

#tagListProject{
    display: flex;
    justify-content: space-around;
    padding: 2rem;
}

#tagProject {
    color: #d8bfbf;
    font-size: 1.5rem;
}

/*Au point md*/
@media screen and (max-width: 768px) {
    #projects{
        padding: 24px 48px 0;
        flex-direction: column;
    }    

    #projectsSectionDivider{
        width: 48px;
        height: 4px;
    }

    #projectsSectionTitle{
        font-size: 56px;
        line-height: 56px;
        margin-bottom: 12px;
        padding: 40px 0 12px;
    }
}



/* Au point sm */
@media screen and (max-width: 640px) {

    #projects{
        padding: "0";
        width: calc(100vw - 32px);
        flex-direction: column;
    }   

    #projectsSectionDivider{
        width: 32px;
        height: 2px;
    }

    #projectsSectionTitle{
        font-size: 32px;
        line-height: 40px;
        font-size: 28px;
        line-height: 32px;
        margin-bottom: 8px;
        padding: 16px 0 8px;
        max-width: 100%;
    }

    #gridContainerProject{
        display: flex;
        flex-direction: column;
        padding: 2rem;
        padding-bottom: 0;
    }

    #blogCardProject{
        width: 100%;
    }

    #cardInfoProject {
        padding:.3rem
    }
}
#tech{
    padding: 32px;
    margin: 0 auto;
    max-width: 1040px;
    box-sizing: content-box;
    position: relative;
    overflow: hidden;
    grid-template-columns: 1fr 1fr;
}

.technologyDivider{
    width: 64px;
    height: 6px;
    border-radius: 10px;
    background-color: #fff;
    background: linear-gradient(90deg, rgba(135,207,152,1) 13%, rgba(70,161,95,1) 27%, rgba(32,124,62,1) 43%, rgba(8,99,33,1) 66%, rgba(5,66,7,1) 88%);
}

#technologyTitle{
    font-weight: 800;
    font-size: 65px;
    line-height: 72px;
    width: -webkit-max-content;
    width: max-content;
    max-width: 100%;
    background: linear-gradient(121.57deg, #FFFFFF 18.77%, rgba(255, 255, 255, 0.66) 60.15%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 2%;
    padding: 58px 0 16px;
}

#technologyText{
    margin-top: 30px;
    max-width: 800px;
    font-size: 24px;
    line-height: 40px;
    font-weight: 300;
    padding-bottom: 3.6rem;
}

#listTechnologies {
    list-style-type: none;
    display: flex;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(5, 25%);
    grid-gap: 20px;
    gap: 20px;
    margin: 3rem 0;
}

#listContainerTechnologies {
    display: grid;
    flex-direction: column;
}

#listItemTechnologies {
    max-width: 320px;
    flex-direction: column;
}

#listParagraphTechnologies {
    font-size: 18px;
    line-height: 30px;
    color: rgba(255, 255, 255, 0.75);
}

#listTitleTechnologies {
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    margin-bottom: 8px;
}

/* Au point lg */
@media screen and (max-width: 1024px) {
    #listTechnologies {
        margin: 64px 0;
    }
}


/* Au point md */
@media screen and (max-width: 768px) {

    #tech{
        padding: 24px 48px 0;
        flex-direction: column;
    }

    .technologyDivider{
        width: 48px;
        height: 4px;
    }

    #technologyTitle{
        font-size: 56px;
        line-height: 56px;
        margin-bottom: 12px;
        padding: 40px 0 12px;
    }

    #technologyText{
        max-width: 670px;
        font-size: 20px;
        line-height: 32px;
        padding-bottom: 24px;
    }

    #listTechnologies {
        margin: 64px 0;
        grid-gap: 24px;
        gap: 24px
    }

    #listItemTechnologies {
        max-width: 203px;
    }

    #listParagraphTechnologies {
        font-size: 16px;
        line-height: 28px;
    }

    #listTitleTechnologies {
        font-size: 24px;
        line-height: 28px;
    }

    #editButtonTechnologies {
        font-size: 16px;
    }

    #deleteButtonTechnologies {
        font-size: 16px;
    }
}


/* Au point sm */
@media screen and (max-width: 640px) {

    #tech{
        padding: "0";
        width: calc(100vw - 32px);
        flex-direction: column;
    }

    .technologyDivider{
        width: 32px;
        height: 2px;
    }

    #technologyTitle{
        font-size: 32px;
        line-height: 40px;
        font-size: 28px;
        line-height: 32px;
        margin-bottom: 8px;
        padding: 16px 0 8px;
        max-width: 100%;
    }

    #technologyText{
        font-size: 16px;
        line-height: 24px;
        padding-bottom: 16px;
    }


    #listTechnologies {
        display: flex;
        flex-direction: column;
        margin: 32px 0;
    }

    #listContainerTechnologies {
        display: flex;
        margin-left: 18px;
    }

    #listItemTechnologies {
        margin-bottom: 14px;
        max-width: 320px;
        flex-direction: row;
    }

    #listParagraphTechnologies {
        font-size: 14px;
        line-height: 22px;
    }

    #listTitleTechnologies {
        font-size: 20px;
        line-height: 28px;
        letter-spacing: 0.02em;
        margin-bottom: 4px;
    }

    #editButtonTechnologies {
        font-size: 14px;
    }

    #deleteButtonTechnologies {
        font-size: 14px;
    }
}
#contact {
    padding: 32px;
    margin: 0 auto;
    max-width: 1040px;
    box-sizing: content-box;
    position: relative;
    overflow: hidden;
    grid-template-columns: 1fr 1fr;
}

#footerWrapperContact {
    width: calc(100vw - 96px);
    max-width: 1040px;
    padding: 2rem 48px 40px;
    margin: 1rem auto;
    box-sizing: content-box;
}

.linkColumnContact {
    display: flex;
    flex-direction: column;
    max-width: 220px;
    width: 100%;
}

.linkItemContact {
    font-size: 18px;
    line-height: 30px;
    color: rgba(255, 255, 255, 0.75);
    margin-bottom: 16px;
    transition: .3s ease;
    position: relative;
    left: 0;
}

.linkItemContact:hover {
    color: rgb(84 214 110);
    left: 6px;
}


#linkListContact{
    display: flex;
    align-items: center;
}


.linkTitleContact{
    font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 24px;
	text-transform: uppercase;
	color: rgba(255, 255, 255, 0.4);
	margin-bottom: 16px;
}

#sloganContact{
	min-width: 280px;
	letter-spacing: 0.02em;
	font-size: 20px;
	font-style: italic;
	line-height: 30px;
}

#socialContainerContact{
    display: flex;
    align-items: center;
}

#socialIconsGitHubContact{
    transition: 0.3s ease;
    color: white;
    border-radius: 20px;
    padding: 8px;
}

#socialIconsGitHubContact:hover{
    background-color: #ac00e6;
    transform: scale(1.2);
    cursor: pointer;
}

#socialIconsLinkedinContact{
    transition: 0.3s ease;
    color: white;
    border-radius: 20px;
    padding: 8px;
}

#socialIconsLinkedinContact:hover{
    background-color: #4d79ff;
    transform: scale(1.2);
    cursor: pointer;
}


#socialIconsContainerContact{
    max-width: 1040px;
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 4%;
}


#licenseArea{
    text-align: center;
}

#license {
    color: rgba(255, 255, 255, 0.5);
	min-width: 280px;
	letter-spacing: 0.02em;
	font-size: 15px;
	line-height: 30px;
}



#titleContact{
    display: block;
    font-weight: 800;
    font-size: 56px;
    line-height: 56px;
    width: -webkit-max-content;
    width: max-content;
    max-width: 100%;
    background: linear-gradient(121.57deg, #FFFFFF 18.77%, rgba(255, 255, 255, 0.66) 60.15%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: auto;
    padding: 58px 0 16px;
}

.inputContact {
    display: block;
	width: 80%;
    margin-right: auto;
    margin-left: auto;
    margin-top: 33px;
    margin-bottom: 33px;
    border-radius: 15px;
    padding: 14px;
}


.messageInputContact{
    display: block;
	width: 80%;
    margin-right: auto;
    margin-left: auto;
    margin-top: 25px;
    margin-bottom: 25px;
    border-radius: 15px;
    padding: 14px;
}


#successMessageContact{
    display: block;
    margin-top: 25px;
    color: #3e9e51;
    font-family: Space Grotesk,sans-serif;
    font-size: 2rem;
    text-align: center;
    margin-bottom: 25px;
}

#errorMessageContact{
    display: block;
    margin-top: 25px;
    color: #f60c0c;
    font-family: Space Grotesk,sans-serif;
    font-size: 2rem;
    text-align: center;
    margin-bottom: 25px;
}


#editButtonContact{
    -webkit-appearance: none;
            appearance: none;
    background-color: #2348C3;
    border-radius: 40em;
    border-style: none;
    box-shadow: #03167E 0 -12px 6px inset;
    box-sizing: border-box;
    color: #C8C9D0;
    cursor: pointer;
    display: block;
    font-family: -apple-system,sans-serif;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -.24px;
    padding: 1.4rem 4.3rem;
    margin: auto;
    margin-bottom: 3px;
    outline: none;
    quotes: auto;
    text-align: center;
    text-decoration: none;
    transition: all .5s;
    -ms-user-select: none;
        user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}

#editButtonContact:hover{
    background-color: #1E33E7;
    box-shadow: #03167E 0 -6px 8px inset;
    transform: scale(1.125);
}

#editButtonContact:active{
    transform: scale(1.025);
}


#submitButtonContact{
    -webkit-appearance: none;
            appearance: none;
	background-color: #1d3c19;
	border-radius: 40em;
	border-style: none;
	box-shadow: #172b15 0 -12px 6px inset;
	box-sizing: border-box;
	color: rgb(186 200 191);
	cursor: pointer;
	display: block;
	font-family: -apple-system,sans-serif;
	font-size: 16px;
	font-weight: 700;
	letter-spacing: -.24px;
	padding: 1.4rem 4.3rem;
	margin: auto;
	margin-bottom: 15px;
	outline: none;
	quotes: auto;
	text-align: center;
	text-decoration: none;
	transition: all .5s;
	-ms-user-select: none;
	    user-select: none;
	-webkit-user-select: none;
	touch-action: manipulation;
}

#submitButtonContact:hover{
    background-color: #429338;
    box-shadow: #1d3c19 0 -6px 8px inset;
    transform: scale(1.125);
}

#submitButtonContact:active{
    transform: scale(1.025);
}


#sectionTitleContact{
    font-weight: 800;
    font-size: 65px;
    line-height: 72px;
    width: -webkit-max-content;
    width: max-content;
    max-width: 100%;
    background: linear-gradient(121.57deg, #FFFFFF 18.77%, rgba(255, 255, 255, 0.66) 60.15%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 2%;
}


#recaptchaStyleContact{
    display: block;
	width: 25%;
    margin: auto;
	border-radius: 14px;
}

/* Au point lg */
@media screen and (max-width: 1024px){
    #linkListContact{
        padding: 32px 0 16px;
    }
}


/* Au point md */
@media screen and (max-width: 768px) {

    #contact {
        flex-direction: column;
        align-items: baseline;
    }

    .linkItemContact {
        font-size: 16px;
        line-height: 28px;
        display: flex;
    }

    #linkListContact{
        width: 100%;
		padding: 32px 0 16px;
		grid-gap: 16px;
		gap: 16px;
    }

    #sloganContact{
        font-size: 16px;
		line-height: 28px;
    }

    #socialContainerContact{
        justify-content: center;
		padding-right: 16px;
		flex-wrap: wrap;
    }

    #socialIconsContainerContact{
        display: flex;
        justify-content: space-between;
    }

    #titleContact{
        font-size: 48px;
        line-height: 48px;
        margin-bottom: 12px;
        padding: 0;
    }

    #editButtonContact{
        font-size: 16px;
    }

    #submitButtonContact{
        font-size: 16px;
    }

    #sectionTitleContact{
        font-size: 48px;
        line-height: 48px;
        margin-bottom: 12px;
        padding: 0;
    }
}


/* Au point sm */
@media screen and (max-width: 640px) {

    #contact {
        display: flex;
        flex-direction: column;
        margin: 0 0 32px;
        align-items: center;
    }

    #footerWrapperContact {
        padding: 0 16px 48px;
        width: calc(100vw - 32px);
    }

    .linkItemContact {
        font-size: 16px;
        line-height: 14px;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
    }

    #linkListContact{
        width: 100%;
		padding: 32px 4px 16px;
		grid-gap: 5px;
		gap: 5px;
    }

    .linkTitleContact{
        font-size: 10px;
		line-height: 12px;
		margin-bottom: 8px;
    }

    #sloganContact{
        line-height: 22px;
		font-size: 14px;
		min-width: 100px;
    }

    #socialIconsContainerContact{
        display: flex;
        width: 100%;
        flex-direction: column;
    }

    #titleContact{
        font-size: 32px;
        line-height: 40px;
        font-size: 32px;
        line-height: 40px;
        margin-bottom: 8px;
        padding: 0;
        max-width: 100%;
    }

    #editButtonContact{
        font-size: 14px;
    }

    #submitButtonContact{
        font-size: 14px;
    }

    #sectionTitleContact{
        font-size: 32px;
        line-height: 40px;
        font-size: 32px;
        line-height: 40px;
        margin-bottom: 8px;
        padding: 0;
        max-width: 100%;
    }
}
#fullbiographysection {
    width: 100%;
    margin: 0px;
    text-align: center;
    top:0px;
    right:0px;
    bottom:0px;
    left:0px;
    background-color: #030503;
}

#fullBiographyImage {
    max-width: 55%;
    max-height: 100%;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 3px 3px 20px #1a551b;
    border: 1px solid #54d66e;
    margin-top: 20px;
}

#fullBiographyParagraph {
    margin-top: 30px;
    max-width: 800px;
    font-size: 24px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    line-height: 40px;
    font-weight: 300;
    padding-bottom: 3.6rem;
    text-align: justify;
}

#buttonFullBiography {
    -webkit-appearance: none;
            appearance: none;
    background-color: #318d26;
    border-radius: 40em;
    border-style: none;
    box-shadow: rgb(5 66 7) 0 -12px 6px inset;
    box-sizing: border-box;
    color: rgb(186 200 191);
    cursor: pointer;
    font-family: -apple-system,sans-serif;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -.24px;
    padding: 1.4rem 4.3rem;
    margin: auto;
    margin-bottom: 64px;
    outline: none;
    quotes: auto;
    text-align: center;
    text-decoration: none;
    transition: all .5s;
    -ms-user-select: none;
        user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  
    &:hover{
      background-color: #429338;
      box-shadow: #1d3c19 0 -6px 8px inset;
      transform: scale(1.125);
    }
  
    &:active{
      transform: scale(1.025);
    }
}


@media screen and (max-width: 768px) {
    #fullBiographyImage{
        width:50%;
        height:50%;
        margin: 200px 0px 20px 75px;
    }  

    #buttonFullBiography{
        font-size: 20px;
    }
}

@media screen and (max-width: 640px) {
    #buttonFullBiography{
        font-size: 14px;
    }
}
#fullProjectSection {
    width: auto;
    height: auto;
    margin: 0px;
    text-align: center;
    position: absolute;
    right: 0px;
    left: 0px;
    background-color: #030503;
}

#fullProjectCarouselSection{
    width: 70%;
    display: inline-block;
}


#imgFullProjectSection{
    width: 100%;
    height: 510px;
    margin-top: 50px;
    border-radius: 10px;
    object-fit: cover;
    overflow: hidden;
}

#paragraphFullProject{
    margin-top: 30px;
    max-width: 800px;
    font-size: 24px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    line-height: 40px;
    font-weight: 300;
    padding-bottom: 3.6rem;
    text-align: justify;
}

#codeSourceLinkGitHubFullProject{
    -webkit-appearance: none;
            appearance: none;
    background-color: #1d3c19;
    border-radius: 40em;
    border-style: none;
    box-shadow: #172b15 0 -12px 6px inset;
    box-sizing: border-box;
    color: rgb(186 200 191);
    cursor: pointer;
    font-family: -apple-system,sans-serif;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -.24px;
    padding: 1.4rem 4.3rem;
    display: block;
    margin: auto;
    margin-bottom: 45px;
    margin-right: 36%;
    margin-left: 36%;
    outline: none;
    quotes: auto;
    text-align: center;
    text-decoration: none;
    transition: all .5s;
    -ms-user-select: none;
        user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  
    &:hover{
      background-color: #429338;
      box-shadow: #1d3c19 0 -6px 8px inset;
      transform: scale(1.125);
    }
  
    &:active{
      transform: scale(1.025);
    }
}


#buttonGoMenuFullProject{
    -webkit-appearance: none;
            appearance: none;
    background-color: #1d3c19;
    border-radius: 40em;
    border-style: none;
    box-shadow: #172b15 0 -12px 6px inset;
    box-sizing: border-box;
    color: rgb(186 200 191);
    cursor: pointer;
    font-family: -apple-system,sans-serif;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -.24px;
    padding: 1.4rem 4.3rem;
    display: block;
    margin: auto;
    margin-bottom: 64px;
    outline: none;
    quotes: auto;
    text-align: center;
    text-decoration: none;
    transition: all .5s;
    -ms-user-select: none;
        user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  
    &:hover{
      background-color: #429338;
      box-shadow: #1d3c19 0 -6px 8px inset;
      transform: scale(1.125);
    }
  
    &:active{
      transform: scale(1.025);
    }
}


@media screen and (max-width: 768px) {
    #codeSourceLinkGitHubFullProject{
        font-size: 20px;
    }

    #buttonGoMenuFullProject{
        font-size: 20px;
    }
}

@media screen and (max-width: 640px) {
    #codeSourceLinkGitHubFullProject{
        font-size: 14px;
    }

    #buttonGoMenuFullProject{
        font-size: 14px;
    }
}
